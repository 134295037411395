.layout-container {
    position: relative;
    min-height: calc(100vh - 96px);
    width: 100%;
    overflow: hidden; /* Oculta el overflow del div principal */
  }
  
  .layout-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../Assets/img/ondas.png');
    background-size: 100%;
    background-position: center;
    opacity: .5; /* Ajusta la opacidad aquí */
    z-index: -1; /* Envía el pseudo elemento detrás del contenido */
  }
  
  .layout-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto; /* Habilita el scroll vertical */
    z-index: 1; /* Asegura que el contenido esté sobre el pseudo elemento */
  }