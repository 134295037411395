@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Bahnschrift;
  src: url(./utils/fonts/bahnschrift.ttf) format("truetype");
}

@font-face {
  font-family: GothanRounded;
  src: url(./utils/fonts/GothamRounded-Medium.otf) format("truetype");
}

@font-face {
  font-family: GothamRounded;
  src: url(./utils/fonts/GothamRounded-Bold.otf) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: GothamRounded;
  src: url(./utils/fonts/GothamRounded-Light.otf) format("truetype");
  font-weight: light;
}

@font-face {
  font-family: GothamRoundedBook;
  src: url(./utils/fonts/GothamRounded-Book.otf) format("truetype");
}

@layer components {
  .btn-primary {
    @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
}

/* Estilos personalizados para los inputs de tipo número */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  @apply appearance-none;
  margin: 0;
}

.no-spinner {
  @apply appearance-none;
}

/* Personalización del scrollbar */
.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  @apply bg-gray-200;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  @apply bg-gray-400 rounded-full;
}

.scrollbar-thin:hover::-webkit-scrollbar-thumb {
  @apply bg-gray-500;
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  @apply rounded-full;
}
