/* Estilo para la barra de desplazamiento */
::-webkit-scrollbar {
    width: 4px; /* Ancho de la barra de desplazamiento */
    background-color: #f1f1f1; /* Color de fondo de la barra de desplazamiento */
}

/* Estilo para el riel de la barra de desplazamiento */
::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color de fondo del riel */
}

/* Estilo para el pulgar de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del pulgar */
    border-radius: 5px; /* Borde redondeado del pulgar */
}

/* Estilo para el pulgar de la barra de desplazamiento al pasar el mouse sobre él */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color del pulgar al pasar el mouse sobre él */
}