
  
  .glassmorphism {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(110, 33, 33, 0.3);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 16px;
    position: relative;
  }
  