/* FolioComponent.css */
@media print {
  .folio-container {
    width: 8.5in;
    height: 11in;
    /* Resto de tus estilos específicos para impresión */
  }
}

/* Estilos para la visualización en pantalla */
.folio-container {
  width: 8.5in;
  height: 11in;
  box-sizing: border-box;
  padding: 0.5rem; /* Margen interno */
  /* Resto de tus estilos */
  background: #fff;
}

.folio-container-border {
  border: 5px solid #030303;
  border-radius: 6px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

/* Header */
.graphics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.logo {
  height: 66px;
}
.qrcode {
  width: 65px;
}

/* Body */
.title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.header p {
  font-size: 0.875rem;
  line-height: 95%;
  text-align: justify;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}

.label-container {
  background: #030303;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0;
}
.label-container h1 {
  color: #fff;
  font-size: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.input-container {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-wrap: wrap;
  row-gap: 0.2rem;
  column-gap: 0.75rem;
  justify-content: flex-start;
}

.input-container p {
  font-size: 0.65rem;
}
.input-container main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
 
}

.checkbox {
  margin-left: 0.5rem;
  border-width: 2px;
  border-color: #000000;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
}

.linebox {
  flex-grow: 1;
  height: 16px;
  border-bottom: 2px solid black;
  margin-left: 0.5rem; /* Ajusta según necesites */
}

.input-container-1 {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-wrap: wrap;
  row-gap: 0.2rem;
  column-gap: 0.75rem;
  justify-content: flex-start;
}

.input-container-1 p {
  font-size: 0.65rem;
}
.input-container-1 main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98px;
  height: 100%;
}

.input-container-2 {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 4rem;
  flex-wrap: wrap;
  row-gap: 0.2rem;
  column-gap: 0.75rem;
  justify-content: flex-start;
}

.input-container-2 p {
  font-size: 0.65rem;
}
.input-container-2 main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
}

.input-container-3 {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size: 0.65rem;
}

.input-container-3 p {
  font-size: 0.65rem;
  margin-right: 2rem;
}

.input-container-3-div {
  display: flex;
}

.input-container-4 {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size: 0.65rem;
}

.input-container-5 {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;

  border-top: 2px solid black;
  border-bottom: 2px solid black;

  height: 50px;

  font-size: 0.65rem;
}

/* Footer */


.aretes-sec{
    font-size: 0.5rem;
    line-height: .8rem;
    column-count: 10;
    column-gap: 0rem;
}

.facturas-sec{
  font-size: 0.5rem;
  line-height: .8rem;
  column-count: 7;
  column-gap: 0rem;
}