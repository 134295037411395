.table {
  width: 100%;
  border-collapse: collapse;
}

.table-col, th, td {
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1.2rem;
}

.table-col span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
